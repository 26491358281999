//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

export class KPIExportDetails {
    public drqSectionId: number;
    public witsmlServerConnectionId: number;
    public kpiSectionId: number;
    public exportedBy: string;
    public isFinalized: boolean;
}

export class KPIExportStatus {
    public progress: number;
    public status: string;
    public reason: string;
}

export enum KPIExportStatuses {
    Live = 'Live',
    NotExported = 'Not Exported',
    Exported = 'Exported',
    InProgress = 'InProgress',
    Finalized = 'Finalized'
}

export enum KpiPeriod {
    LastWeek = 'Last week',
    LastMonth = 'Last month',
    LastQuarter = 'Last quarter',
    LastYear = 'Last year'
}